.Main-webrtc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 46vh;
    width: 100%;
    background-image: url("https://1.bp.blogspot.com/-3g1DuB01mps/TxcUfX0nMCI/AAAAAAAAAN4/-vlJtV0lIa0/s1600/IMG_7247a.jpg");
    background-size: cover;
    background-position: center;
}

.Placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(14px + 1vh);
}