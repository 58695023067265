.dev-ui {
    position: relative;
    display: flex; /* Ensures the children are arranged in a row */
    flex-direction: row; /* Sets the direction of the flex items */
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    flex-wrap: wrap; /* Allows wrapping of items to a new line if needed */
    align-items: center; /* Aligns items vertically in the center */
    width: fit-content;
}

.dev-ui input {
    margin-right: 10px;
}

.volume-controls {
    display: flex;
    flex-direction: row; /* Changes direction to row */
    margin-left: 10px; /* Adjust margin as necessary */
}

.volume-controls button {
    margin-right: 5px; /* Adjust margin as necessary */
}

.playback-controls {
    display: flex;
    flex-direction: row; /* Changes direction to row */
    margin-left: 10px; /* Adjust margin as necessary */
    justify-content: space-around;
}