.Permission-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    justify-content: space-around;
    width: 80vw;
    border: 1px solid gray;
}

.Permission-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 20vh;
    padding: calc(10px + 1vh);
    font-size: calc(10px + 1.8vmin);
}

.Permission-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 13vh;
    width: 70vw;
}

.Cancel-button {
    cursor: pointer;
    font-size: calc(10px + 1.1vmin);
    min-width: calc(40px + 2vw)
}

.Ok-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(8px + 0.5vmin);
    font-size: calc(18px + 2.5vmin);
    background-color: #93c47d;
    border: 1px solid lightgray;
}

.placeholder {
    min-width: calc(40px + 2vw)
}