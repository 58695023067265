.tempo-slider-container {
    display: flex;
    align-items: center;
    height: 200px;
}

.tempo-slider-wrapper {
    display: flex;
    align-items: center;
}

.tempo-slider {
    position: relative;
    width: 50px;
    height: 200px;
    background-color: #ddd;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.tempo-slider-fill {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #4CAF50;
    transition: height 0.1s linear;
}

.tempo-slider-handle {
    position: absolute;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #555;
    border-radius: 5px;
    transform: translateY(50%);
}

.tempo-value {
    font-size: 14px;
    font-weight: bold;
    min-width: 70px;
}