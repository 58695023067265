.Start-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(80px + 8vmin);
    width: calc(80px + 8vmin);
    justify-content: center;
    border: 1px solid lightgray;
    border-radius: calc(40px + 4vmin);
    cursor: pointer;
    background-color: ghostwhite;
}

.Start-text {
    font-size: calc(14px + 2.5vmin);
    user-select: none;
    pointer-events: none;
}