.App {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-main {
    background-color: #ffffff;
    /*min-height: calc(40vh - 56px);*/
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #000000;
}

.App-subtitle {
    background-color: #282c34;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.Group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: calc(100vh - 56px);
    width: 100vw;
}

.App-Title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15vh;
    width: 100vw;
    font-size: calc(22px + 4vh);
}

.Main-screen {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
}

.App-bitrate {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 2;
    font-size: small;
}

.select {
    -webkit-touch-callout: default; /* iOS Safari */
    -webkit-user-select: all; /* Safari */
    -moz-user-select: all; /* Old versions of Firefox */
    -ms-user-select: all; /* Internet Explorer/Edge */
    user-select: all; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.App-instruction {
    font-size: 14px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.current-piece {
    font-size: 20px;
    margin-bottom: 10px;
}

.applied-modifiers {
    font-size: 16px;
    margin-bottom: 10px;
}

.system-status {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}

.control-tempo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.control-button-wrapper {
    margin-right: 20px;
    margin-left: 20px;
}

.video-control-buttons {
    margin-bottom: 10px;
}

.video-toggle-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.privacy-info {
    font-size: 14px;
    color: #888;
    margin-bottom: 20px;
    max-width: 600px;
    text-align: center;
}

.headphone-notice {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #444;
    color: white;
    padding: 15px 30px;
    border-radius: 5px;
    text-align: center;
    z-index: 1000;
}

.headphone-notice button {
    background-color: #61dafb;
    color: black;
    border: none;
    padding: 8px 12px;
    margin-top: 10px;
    border-radius: 3px;
    cursor: pointer;
}
