.videoMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: auto;
    background-color: white;
}

@media screen and (orientation: portrait) {
    .videoMainContainer {
        padding-top: 5px;
        padding-left: 10px;
    }
}

.videoDivContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: var(--selectedThumbnailHeight);
}

.preloadedVideoDiv {
    display: none;
    margin: 0;
}

.playingVideoDiv {
    border: 0;
    margin: 0;
    padding: 0;
    z-index: 3;
    pointer-events: auto;
}

.metaText {
    margin-top: 0px;
    font-size: 0.8rem;
    max-width: calc(var(--scoreViewerOptimalWidth) - var(--selectedThumbnailWidth));
}

@media screen and (orientation: portrait) {
    .metaText {
        max-width: calc(100vw - var(--selectedThumbnailWidth) - 10px);
        margin-left: 5px;
    }
}

@media screen and (orientation: landscape) {
    .metaText {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.placeHolder {
    position: absolute;
    left: 15px;
    width: 120px;
    background-color: whitesmoke;
    padding: 10px;
    top: 12px;
    opacity: 0.9;
    color: grey;
    font-size: 16px;
}
.playingUnderline {
    display: flex;
    height: 2px;
    background-color: #006600;
    margin-top: 4px;
    padding: 0;
}

