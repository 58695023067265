.Menu-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: calc(5vh + 10px);
    width: 100vw;
    justify-self: end;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Menu-tab {
    display: flex;
    font-size: larger;
    flex: 1;
    height: calc(5vh + 10px);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 0.5px solid darkgrey;
    background-color: white;
}

.Menu-tab-active {
    display: flex;
    font-size: larger;
    flex: 1;
    height: calc(5vh + 10px);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 0.5px solid darkgrey;
    background-color: grey;
    color: white;
}