.Account-page {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    justify-content: space-evenly;
    margin-top: 10px;
}

.stat {
    padding: 8px;
    text-align: start;
    display: flex;
}

.Account-label {
    width: 100px;
}