.Intro-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
}

.Intro-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 25vh;
    font-size: calc(28px + 6.5vmin);
}

.Title-text {
    display: flex;
    justify-self: center;
    align-self: center;
    user-select: none;
}

.Intro-slogan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 15vh;
    font-size: calc(11px + 3vmin);
    user-select: none;
}

.Intro-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
}

.Intro-terms {
    visibility: hidden;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 10vh;
    font-size: calc(10px + 1.1vmin)
}